.company-report {
  margin-top: 2vw;
  // padding: 0 10vw 0 10vw;
  // flex-direction: column;
  gap: 2vw;
  // height: 75vh;
  // padding-bottom: 3vw;
  min-height: 41vw;
  display: flex;
  flex-direction: column;
  text-align: justify;
  background: url("../../assets/background-all.png");
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-attachment: fixed;
  min-width: 90vw;
  padding: 0 4vw 2vw 4vw;
  .filter {
    color: white;
    font-weight: bold;
    display: flex;
    font-size: 1vw;
    align-items: center;
    z-index: 1;
    gap: 0.5vw;
    // position: sticky;
    justify-content: space-between;
    top: 8.5vw;
    height: 3vw;
    background-color: transparent;
    .radioButtons {
      gap: 1vw;
      .ant-radio-wrapper {
        font-size: 1vw;

        color: white;
        &-checked {
          color: #ff1f69;
        }
      }
    }
  }
  .table {
    color: white;
    .row {
      &-heading {
        font-weight: 700;
        background-color: #5d5a5ab2;
        // position: sticky;
        top: 13vw;
        height: 5vw;
        align-items: center;
        border-radius: 0.5vw 0.5vw 0 0;
        .ant-col {
          font-size: 1vw;
          text-align: center;
          padding-left: 1vw;
        }
      }
      &-datasource {
        margin: 0.5vw 0;
        background: linear-gradient(135deg, #ffffff1a, #ffffff1a);
        min-height: 3vw;
        align-items: center;
        padding-inline: 3px;
        overflow-y: auto;
        .ant-col {
          font-size: 1vw;
          text-align: center;
          padding-left: 1vw;
        }
      }
    }
    .scrolling{
      overflow-y: scroll;
    height: 60vw;

    }
  }
  .download{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5vw;
    cursor: pointer;

  &-icon{
    height: 1.5vw;
    width: 1.5vw;
    padding-inline: 2vw;
   
  }
}
}
