.card {
  display: flex;
  flex-direction: column;
  width: 20vw;
  overflow: hidden;
  border-radius: 1vw;
  :hover {
    cursor: pointer;
  }
  .card-title {
    font-size: 1.1vw;
    color: white;
    backdrop-filter: blur(4px);
    background: linear-gradient(
      135deg,
      hsla(0, 0%, 100%, 0.1),
      hsla(0, 0%, 100%, 0.1)
    );
    height: 3.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter, sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .content {
    border-radius: 0 0 1vw 1vw;
    display: flex;
    justify-content: space-between;
    backdrop-filter: blur(4px);
    background: linear-gradient(
      135deg,
      hsla(0, 0%, 100%, 0.2),
      hsla(0, 0%, 100%, 0.1)
    );
    align-items: center;
    height: 6vw;
    padding: 0 1vw;
    &-data {
      // margin-left: 1vw;
      // margin-right: 1vw;
      display: flex;
      flex-direction: column;
      gap: 1vw;
      text-align: center;
      .label {
        // width: 8vw;
        font-size: 1.1vw;
        color: #ff1f69;
        font-family: Inter, sans-serif;
        font-weight: 500;
        text-align: center;
      }
      .value {
        font-size: 1.1vw;
        color: white;
        font-family: Inter, sans-serif;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}
