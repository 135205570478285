.music-tab {
  display: flex;
  flex-direction: column;
  text-align: justify;
  background: url("../../assets/background-all.png");
  // background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  width: 90vw;
  height: 65vw;
  padding: 0 4vw 2vw 2vw;

  .heading {
    display: flex;
    justify-content: space-around;
    margin: 2vw;
    align-items: center;
    flex-direction: row;

    // 15/09
    gap: 6.5vw;

    .filters {
      display: flex;
      flex-direction: row;
      padding: 0 0 1vw 0;
      gap: 0.55vw;
      .aplybtn {
        width: 2.3vw;
        height: 2.3vw;
        cursor: pointer;
      }
    }

    .title {
      color: #ff1f69;
      font-size: 3vw;
      font-weight: 600;
      font-family: Inter, sans-serif;
      margin-bottom: 1vw;
      // margin-right: 75vw;
      display: flex;
      flex-direction: column;
      margin-left: 0px;

      // width: 50%;
      justify-content: flex-end;
      // gap: 7vw;
      .back-btn {
        display: flex;
        margin-top: 1vw;
        color: white;
        font-size: 1.3vw;
        // padding: 0 4vw 0 4vw;
        // margin-left: 4vw;
        align-items: center;
        gap: 0.5vw;
        :hover {
          cursor: pointer;
        }
      }
    }
    .ant-select-selector {
      height: 2.3vw;
      // min-height: 2.22vw;
      // max-height: 4vw;
      width: 12.61vw;
      border-color: #3b28ed;
      overflow: auto;
      color: white;
      // color:#FFFFFF;
      background-color: #3b28ed;
      cursor:pointer  !important;
      .ant-select-selection-search-input {
        color: red;
      }
    }
    .ant-select-selection-placeholder {
      color: #ffffff;
    }
    .ant-select-arrow {
      color: white;
    }
    .search-box {
      display: flex;
      align-items: center;
      gap: 0.5vw;
      border: 2px solid #858585;
      border-radius: 0.5vw;
      height: 3vw;

      .ant-select-selector {
        height: 4vw;
        width: 40vw;
        background-color: transparent;
        color: #858585;
        font-size: 1.3vw;
        border: none;

        .ant-select-selection {
          &-search-input {
            font-size: 1.3vw;

            height: 4vw;
            color: #858585;
          }
          &-placeholder {
            align-self: center;
            color: #858585;
          }
        }
      }
      .search-btn {
        color: white;
        margin-right: 1vw;
        background-color: #090909;
        border: none;
        :hover {
          cursor: pointer;
        }
        .search-icon {
          font-size: 1.3vw;
          color: #858585;
        }
      }
    }
  }

  .details {
    // display: flex;
    // margin-left: 8vw;
    // margin-right: 8vw;
    // margin-top: 3vw;
    padding-bottom: 3vw;
    display: flex;
    justify-content: center;
    .title {
      display: flex;
      color: #ff1f69;
      font-size: 3vw;
      font-weight: 800;
      margin-bottom: 3vw;
    }
  }

  .render-data {
    display: flex;
    flex-direction: column;
    // height: 50vw;

    //18/09
    max-height: 70vh;
    max-width: 70vw;
    overflow-y: scroll;

    // scrollbar-width: none;
    scrollbar-width: none; /* Firefox */
    -webkit-scrollbar-width: none; /* Webkit (Chrome, Safari) */
    // width: 70%;
    .music-card {
      display: flex;
      flex-grow: 1;
      justify-content: space-evenly;
      padding-left: 2vw;
      margin-bottom: 1vw;
      border-radius: 0.5vw;
      min-height: 4.5vw;
      align-items: center;
      .values {
        display: flex;
        // width: 15vw;
        justify-content: space-around;
        align-items: center;
        margin-right: 6vw;
        // .percentage {
        //   height: 5vw;
        //   width: 5vw;
        //   border-radius: 50%;
        //   border: 1px solid white;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   font-size: 1.5vw;
        //   color: #ff1f69;
        // }
        .name {
          color: white;
          font-size: 1.5vw;
          width: 6vw;
        }
      }

      // 18/09
      /* Style for Webkit browsers (Chrome, Safari) */
      ::-webkit-scrollbar {
        width: 0; /* Hide the scrollbar */
      }

      .music {
        audio::-webkit-media-controls-panel {
          background-color: #555555;
          color: #555555;
        }

        audio::-webkit-media-controls-current-time-display,
        audio::-webkit-media-controls-time-remaining-display {
          color: white;
        }
      }
      .audio {
        width: 30vw;
        font-size: 1vw;
        height: 3vw;
      }
      .btn {
        display: flex;
        justify-content: center;
        cursor: pointer;

        .view-btn {
          height: 3vw;
          width: 7vw;
          background-color: transparent;
          border-radius: 10vw;
          border: none;
          font-size: 1.25vw;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          cursor: pointer;
          .text {
            color: white;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    .no-data-found {
      color: white;
      animation: fadeInOut 2s linear infinite;
    }
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}
.ant-pagination {
  background-color: black;
  display: flex;
  justify-content: center;
  .ant-pagination-item-active {
    background-color: #090909;
  }
  .ant-pagination-item-link {
    color: white !important;
  }
  .ant-pagination-item-ellipsis {
    color: white !important;
  }
  .ant-pagination-item {
    background-color: transparent;
    a {
      background-color: transparent;
      color: white !important;
    }
  }
}
