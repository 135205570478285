.brandinfo-container {
  // display: flex;
  margin-top: 2vw;
  // padding: 0 10vw 0 10vw;
  // flex-direction: column;
  gap: 2vw;
  // height: 75vh;
  // padding-bottom: 3vw;

  display: flex;
  flex-direction: column;
  text-align: justify;
  background: url("../../assets/background-all.png");
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 90vw;
  padding: 0 4vw 2vw 4vw;
  .brand-value-container {
    .brand-value-title {
      margin-bottom: 1.2vh;
      color: #ff1f69;
      font-family: "Inter", sans-serif;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 90.023%; /* 16.204px */
      width: 100%;
      text-align: left;
    }
    .brand-value-info {
      color: #fff;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      text-align: left;
      width: 80vw;
      white-space: pre-wrap;
    }
  }
  .button {
    // width: 222px;
    // height: 40px;
    display: flex;

    justify-content: center;
    gap: 6.7vw;
    .button-details {
      cursor: pointer;
      display: flex;
      min-height: 40px;
     min-width: 12vw;
      background-image: linear-gradient(90deg, #ff1f69, #3b28ed);
      color: #fff;
      text-align: center;
      font-family: "Inter", sans-serif;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 600;
      align-items: center;
      justify-content: center;
    }
    .button-details:hover {
      background-image: linear-gradient(270deg, #ff1f69, #3b28ed);
      transition: background-image 0.3s ease;
    }
  }
}

.wordcloud-img {
  // width: -webkit-fill-available;
  // padding: 2%;
  border-radius: 10px;
  width: 60vw;
  height: 45vw;
}
.ant-modal-close-x {
  color: white;
  background: #838383;
  font-size: 25px;
}
// .ant-modal-content {
//   padding: 10px !important;
// }
