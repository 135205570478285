// * {
//   font-family: Inter, sans-serif;
// }
.App {
  text-align: center;
  .toaster {
  }
  .Toastify {
    .Toastify__toast-body {
      .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
        display: none;
      }
      .Toastify__toast {
        &-container {
          right: 50%;
        }
      }
    }
    .Toastify__toast-container--top-right {
      left: 40%;
    }
    .Toastify__close-button.Toastify__close-button--light {
      display: none;
    }
  }
  // .bck-btn {
  //   display: flex;
  //   margin-top: 1vw;
  //   color: white;
  //   font-size: 1.3vw;
  //   padding: 0 4vw 0 4vw;
  //   align-items: center;
  //   gap: 0.5vw;
  //   :hover {
  //     cursor: pointer;
  //   }
  // }
  .bck-btn {
    display: flex;
    margin-top: 0.5vw;
    color: white;
    background-color: black;
    z-index: 1;
    font-size: 1.3vw;
    padding: 0.5vw 4vw 0.5vw 4vw;
    align-items: center;
    gap: 0.5vw;
    position: sticky;
    top: 6vw;
    .bck-btn:hover {
      cursor: pointer;
    }
  }

  .search-box {
    display: flex;
    gap: 0.5vw;
    border: 2px solid #858585;
    border-radius: 0.5vw;
    height: 2vw;
    text-align: left;

    .ant-select-selector {
      height: 2vw;
      width: 30vw;
      background-color: transparent;
      color: #858585;
      font-size: 1vw;
      border: none;

      .ant-select-selection {
        &-search-input {
          font-size: 1vw;

          height: 2vw;
          color: #858585;
        }
        &-placeholder {
          align-self: center;
          color: #858585;
        }
      }
    }
    .search-btn {
      color: white;
      margin-right: 1vw;
      background-color: #090909;
      border: none;
      :hover {
        cursor: pointer;
      }
      .search-icon {
        font-size: 1vw;
        color: #858585;
      }
    }
  }
  .background {
    background-color: #090909;
    .header-nav {
      margin-left: 1.2vw;
      margin-right: 1.2vw;
      border-bottom: 0.1em solid #636363;
      height: 6vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: -webkit-sticky;
      position: sticky;
      z-index: 100;
      top: 0;
      background-color: #090909;

      .logo-search {
        display: flex;
        align-items: center;
      }
      .brandmusic-logo {
        width: 10vw;
        display: flex;
        margin-left: 2.5vw;
        margin-right: 2.5vw;
        cursor: pointer;
      }

      .lgt {
        display: flex;
        align-items: center;
        margin-right: 2.5vw;

        gap: 1vw;
        &-text {
          font-size: 1vw;
          color: white;
        }
        &-icon {
          color: white;
          font-size: 2vw;
        }
      }
    }
  }
}
.ant-dropdown-menu-title-content {
  font-size: 1.1vw;
  background: -webkit-linear-gradient(180deg, #ff1f69 0%, #3b28ed 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// background: linear-gradient(270deg, #ff1f69 0%, #3b28ed 55.21%);