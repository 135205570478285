.brand-tab {
  display: flex;
  flex-direction: column;
  text-align: justify;
  background: url("../../assets/background-all.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: contain;
  background-attachment: fixed;
  width: 90vw;
  height: 85vh;
  padding: 0 4vw 2vw 4vw;

  // 14/09
  // .heading-and-filter{
  //   // background-color: black;
  //   z-index: 1;
  //   gap: 0.5vw;
  //   position: sticky;
  //   top: 9vw;
  // }

  .heading {
    display: flex;
    justify-content: space-between;
    // margin: 2vw 0vw 2vw 0vw;
    align-items: end;
    align-items: center;

    // 14/09
    z-index: 1;
    gap: 0.5vw;
    position: sticky;
    // top: 11vw;
    top: 7vw;

    .title {
      color: #ff1f69;
      font-size: 3vw;
      font-weight: 600;
      font-family: Inter, sans-serif;
      // margin-bottom: 1vw;
    }
    .filters {
      display: flex;
      flex-direction: row;
      z-index: 1;
      // padding: 0 0 1vw 0;
      padding: 0;
      gap: 0.55vw;

      .aplybtn {
        width: 2.3vw;
        height: 2.3vw;
        cursor: pointer;
      }
    }
    .ant-select-selector {
      height: 2.3vw;
      width: 12.61vw;
      border-color: #3b28ed;
      overflow: auto;
      color: white;
      background-color: #3b28ed;
      cursor: pointer !important;
      .ant-select-selection-search-input {
        color: white;
      }
      .ant-select-selection-placeholder {
        color: #ffffff;
      }
    }
    .ant-select-arrow {
      color: white;
    }
    .search-box {
      display: flex;
      align-items: center;
      gap: 0.5vw;
      border: 2px solid #858585;
      border-radius: 0.5vw;
      height: 3vw;
      .ant-select-selector {
        height: 4vw;
        width: 40vw;
        background-color: transparent;
        color: #858585;
        font-size: 1.3vw;
        border: none;
        .ant-select-selection {
          &-search-input {
            font-size: 1.3vw;
            height: 4vw;
            cursor: pointer;
            color: #858585;
          }
          &-placeholder {
            align-self: center;
            color: #858585;
          }
        }
      }
      .search-btn {
        color: white;
        margin-right: 1vw;
        background-color: #090909;
        border: none;
        :hover {
          cursor: pointer;
        }
        .search-icon {
          font-size: 1.3vw;
          color: #858585;
        }
      }
    }
  }
  // .renderCards {
  //   display: flex;
  //   flex-wrap: wrap;
  //   align-content: flex-start;
  //   // justify-content: space-between;
  //   height: 60vw;
  //   width: 90vw;
  //   align-self: baseline;
  //   gap: 3vw;
  // }
  .filter-mail {
    color: white;
    padding-bottom: 2vw;
    font-weight: bold;
    display: flex;
    // gap: 1vw;
    font-size: 1vw;
    align-items: center;

    // 14/09
    z-index: 1;
    gap: 0.5vw;
    position: sticky;
    top: 11vw;

    .radioButtons {
      gap: 1vw;
      .ant-radio-wrapper {
        font-size: 1vw;

        color: white;
        &-checked {
          color: #ff1f69;
        }
      }
    }
  }
  //15/09

  .renderCards-and-pagination {
    display: flex;
    flex-wrap: wrap;
    width: 95vw;
    height: 82vh;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE 11 */
    margin: 0 auto;
    box-sizing: border-box; /* Include padding in the element's total width */
  }

  /* Hide the scrollbar in Webkit-based browsers */
  .renderCards-and-pagination::-webkit-scrollbar {
    display: none;
  }
  .ant-pagination {
    background-color: black;
    display: flex;
    height: 4vw;
    justify-content: center;
    margin: 0.5vw auto;
  }

  .renderCards {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%; /* Adjust to take full width of parent */
    height: 62vw;
    row-gap: 2.8vw;
    column-gap: 3.55vw;
  }
}
.brand-model {
  min-width: 85vw;
  background-color: #090909;
  min-height: 50vw;
  .ant-modal-header {
    border-bottom: 3px solid black;
  }
  .ant-modal-content {
    background-color: #222222;
    padding: 0px;
    .ant-modal-close {
      display: none;
    }
    .header-set {
      background-color: #222222;
      padding-left: 3vw;
      padding-right: 3vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .brand-title {
        height: 7vw;
        color: white;
        display: flex;
        align-items: center;
        font-size: 1.4vw;
        font-weight: 500;
      }
      .close-btn {
        color: white;
        font-size: 2vw;
      }
    }
  }
  .ant-modal-body {
    margin-top: 1vw;
    background-color: #222222;
    min-height: 43.7vw;
  }
  .details {
    &-show {
      padding-left: 3vw;
      margin-top: 2vw;
      color: white;
      min-height: 6vw;
    }
    &-name {
      color: #ff1f69;
      font-size: 1.3vw;
      margin-bottom: 1vw;
    }
    &-value {
      font-size: 1.3vw;
      font-weight: 500;
      .label {
        font-weight: bold;
        min-width: 5vw;
      }
    }
  }
  .btn {
    margin-top: 2vw;
    display: flex;
    justify-content: center;
    .genrt-btn {
      height: 4.5vw;
      width: 21vw;
      background-color: #3b28ed;
      border-radius: 10vw;
      border: #3b28ed;
      font-size: 1.25vw;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 3vw;
      :hover {
        cursor: pointer;
      }
      .text {
        color: white;
      }
    }
  }
}
