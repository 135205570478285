.home-page {
  display: flex;
  // margin: 0vw 4vw 0vw 4vw;
  // background-color: #090909;
  // padding-bottom: 8.5vw;
  min-height: 55vw;
  background-image: url("../../assets/Landing_page.svg");
  background-repeat: no-repeat;
  background-size: cover;
  // margin-left: 4vw;
  // margin-right: 4vw;
  // margin-top: 8vw;
  .sections{
    width: 100%;
    display: flex;
    justify-content: right;
    margin-right: 3vw;
  .section1 {
    margin-top: 12vw;
    display: flex;
    flex-direction: column;
    text-align: left;
    .logo{
      height: 12vw;
      padding-bottom: 3vw;
    }
    .sonic-revo {
      font-size: 1.5vw;
      color: #ff1f69;
      font-weight: 800;
      -webkit-text-stroke:1px;
    }
    .Ai-tech {
      font-size: 1.5vw;
      color: white;
      font-weight: 800;
      max-width: 50vw;
    }
    .composed {
      margin: 2vw 0vw ;
      font-size: 1.5vw;
      font-weight: 800;
      color: white;
      display: flex;
      max-width: 40vw;
      &-text{
      font-size: 1vw;
      color: white;
      display: flex;
      max-width: 40vw;
      padding-bottom: 2vw
      }
    }
    
    
  }
  
}
}

.lgn-btn{
  height: 3.5vw;
    width: 12vw;
    background-color: #3b28ed;
    border-radius: 10vw;
    border: #3b28ed;
    font-size: 1.25vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    .text {
      color: white;
    }
    .arrow-icon {
      color: white;
    }
    
    
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 4px solid #ff1f69;
        border-radius: 10vw;

        animation: clippath 2s infinite linear;
    }
    @keyframes clippath {
      0% {
        clip-path: inset(0 0 95% 0);
    }      100% {
          clip-path: inset(0 0 95% 0);
      }
      25% {
          clip-path: inset(0 95% 0 0);
      }
      50% {
          clip-path: inset(95% 0 0 0);
      }
      75% {
          clip-path: inset(0 0 0 95%);
      }
  }
}