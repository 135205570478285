.dashboard {
  display: flex;
  flex-direction: column;
  text-align: justify;
  background: url("../../assets/background-all.png");
  // background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  width: 90vw;
  // height: 65vw;
  padding: 0 4vw 2vw 2vw;
  text{
    fill: white;
  }
  .count-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .count {
      min-height: 5vw;
      margin: 2vw 2vw 2vw 0vw;
      border-radius: 1vw 1vw 1vw 1vw;
      display: flex;
      flex-direction: column;
      color: white;
      // gap: 1vw;
      justify-content: space-evenly;
      backdrop-filter: blur(4px);
      background: linear-gradient(
        135deg,
        hsla(0, 0%, 100%, 0.2),
        hsla(0, 0%, 100%, 0.1)
      );
      align-items: center;
      width: fit-content;
      // height: 6vw;
      // align-items: baseline;
      padding: 0 1vw;
      min-width: 10vw;
      .value {
        color: #ff1f69;
        font-size: 1.5vw;
      }
      p {
        margin: 0;
        font-size: 1vw;
      }
    }
    .filters {
      display: flex;
      flex-direction: column;
      z-index: 1;
      // padding: 0 0 1vw 0;
      padding: 0;
      gap: 0.55vw;

      .aplybtn {
        width: 2.3vw;
        height: 2.3vw;
      }
    }
    .ant-select-selector {
      height: 2.3vw;
      width: 12.61vw;
      border-color: #3b28ed;
      overflow: auto;
      color: white;
      background-color: #3b28ed;
      .ant-select-selection-search-input {
        color: white;
      }
      .ant-select-selection-placeholder {
        color: #ffffff;
      }
    }
    .ant-select-arrow {
      color: white;
    }
  }
  .charts {
    display: flex;
    gap: 5vw;
    .radial-chart {
      border-radius: 1vw 1vw 1vw 1vw;
      display: flex;
      flex-direction: column;
      color: white;
      justify-content: space-between;
      backdrop-filter: blur(4px);
      background: linear-gradient(
        135deg,
        hsla(0, 0%, 100%, 0.2),
        hsla(0, 0%, 100%, 0.1)
      );
      align-items: center;
      width: fit-content;
      // height: 6vw;
      // align-items: baseline;
      padding: 0 1vw;
      .labels {
        .text {
          display: flex;
          margin-bottom: 1vw;
          gap: 1vw;
          align-items: center;
          .value {
            font-weight: bold;
          }
        }
      }
    }
    .line-chart {
      border-radius: 1vw 1vw 1vw 1vw;
      display: flex;
      flex-direction: column;
      color: white;
      justify-content: space-between;
      backdrop-filter: blur(4px);
      background: linear-gradient(
        135deg,
        hsla(0, 0%, 100%, 0.2),
        hsla(0, 0%, 100%, 0.1)
      );
      align-items: center;
      width: fit-content;
      // height: 6vw;
      // align-items: baseline;
      padding: 0 1vw;
      .apexcharts-legend-text {
        color: white !important;
      }
      text {
        fill: white;
      }
      .apexcharts-tooltip.apexcharts-theme-light {
        color: black;
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .charts {
flex-direction: column;  }
}