.search-box {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    border: 2px solid #858585;
    border-radius: 0.5vw;
    height: 3vw;

    .ant-select-selector {
      height: 4vw;
      width: 40vw;
      background-color: transparent;
      color: #858585;
      font-size: 1.3vw;
      border: none;

      .ant-select-selection {
        &-search-input {
          font-size: 1.3vw;

          height: 4vw;
          color: #858585;
        }
        &-placeholder {
          align-self: center;
          color: #858585;
        }
      }
    }
    .search-btn {
      color: white;
      margin-right: 1vw;
      background-color: #090909;
      border: none;
      :hover {
        cursor: pointer;
      }
      .search-icon {
        font-size: 1.3vw;
        color: #858585;
      }
    }
  } 