.tabs {
  // margin-top: 4vw;
    width: 33vw;
  &-open {
    // background-color: red;
    // margin-left: 8vw;
    // margin-right: 8vw;

    .ant-tabs-nav {
      // width: 15vw;
      &-wrap {
        background-color: #090909;
      }
      .ant-tabs-ink-bar {
        &-animated {
          background-color: #ff1f69;
          height: 4px;
        }
      }
    }
    // .ant-tabs{
    //   box-sizing: border-box;
    //   padding: 0 0 0 2vw;
    // }
    .ant-tabs-tab {
      color: white;
      font-size: 1vw;
      font-weight: 500;
      font-family: Inter, sans-serif;

      &-btn {
        font-size: 1.3vw;
      }

      &-active {
        .ant-tabs-tab-btn {
          color: white !important;
        }
      }
    }
  }
}
