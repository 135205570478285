.suggested-music {
  min-height: 41vw;
  margin-top: 2vw;
  // padding: 0 10vw 0 10vw;
  // flex-direction: column;
  gap: 2vw;
  // height: 75vh;
  // padding-bottom: 3vw;

  display: flex;
  flex-direction: column;
  text-align: justify;
  background: url("../../assets/background-all.png");
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 90vw;
  padding: 0 4vw 2vw 4vw;
  .bck-btn {
    display: flex;
    margin-top: 1vw;
    color: white;
    font-size: 1.3vw;
    padding-left: 3vw;
    align-items: center;
    gap: 0.5vw;
    :hover {
      cursor: pointer;
    }
  }
  .details {
    // display: flex;
    margin-left: 8vw;
    margin-right: 8vw;
    margin-top: 3vw;
    padding-bottom: 3vw;
    .title {
      display: flex;
      color: #ff1f69;
      font-size: 3vw;
      font-weight: 800;
      margin-bottom: 3vw;
    }
  }
  .render-data {
    display: flex;
    flex-direction: column;
    .music-card {
      display: flex;
      background-color: #222222;
      justify-content: space-between;
      padding-inline: 2vw;
      margin-bottom: 1vw;
      border-radius: 0.5vw;
      height: 9vw;
      align-items: center;
      .values {
        display: flex;
        width: 12vw;
        justify-content: space-between;
        align-items: center;
        .percentage {
          height: 5vw;
          width: 5vw;
          border-radius: 50%;
          border: 1px solid white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5vw;
          color: #ff1f69;
        }
        .match {
          color: white;
          font-size: 1.5vw;
        }
      }
      .react-audio-player {
        width: 30vw;
      }
      audio::-webkit-media-controls-play-button,
      audio::-webkit-media-controls-panel {
        background-color: #555555;
        color: #555555;
      }
    }
  }
}
