.generate-mail-container {
  margin-top: 2vw;
  // padding: 0 10vw 0 10vw;
  // flex-direction: column;
  gap: 2vw;
  // height: 75vh;
  // padding-bottom: 3vw;
  min-height: 41vw;
  display: flex;
  flex-direction: column;
  text-align: justify;
  background: url("../../assets/background-all.png");
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-attachment: fixed;
  min-width: 90vw;
  padding: 0 4vw 2vw 4vw;
  .title {
    color: #ff1f69;
    font-family: Inter, Sans-serif;
    font-size: 1.5vw;
    font-weight: 600;
    // margin-bottom: 1vw;
  }
  .generate-mail-container-details {
    // padding-left: 10vw;
    display: flex;
    text-align: left;
    color: #fff;
    font-family: Inter, Sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 118.023%; /* 18.884px */
    // width: 40vw;
    .details {
      font-family: Inter, sans-serif;
      font-size: 1vw;
      // width: 50vw;
      white-space: pre-wrap;
    }
  }
  .button {
    // width: 222px;
    // height: 40px;
    display: flex;

    // justify-content: center;
    gap: 2vw;
    .button-details {
      cursor: pointer;
      display: flex;
      min-height: 2.2vw;
      min-width: 12vw;
      background-image: linear-gradient(90deg, #ff1f69, #3b28ed);
      color: #fff;
      text-align: center;
      font-family: "Inter", sans-serif;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 600;
      align-items: center;
      justify-content: center;
    }
    .button-details:hover {
      background-image: linear-gradient(270deg, #ff1f69, #3b28ed);
      transition: background-image 0.3s ease;
    }
  }
}
.ant-modal-content {
  background-color: #d9d9d9;
  min-width: 20vw;
  .ant-modal-body {
    .table-content {
      border-spacing: inherit;

      .heading-row {
        background-color: #858585;
      }
      tr {
        height: 3vw;
        // background-color: #D9D9D9;
        th {
          color: white;
          font-weight: bold;
          text-align: left;
          padding-left: 2vw;
        }
        td {
          padding-left: 2vw;
        }
        .name {
          min-width: 12vw;
        }
        .designation {
          min-width: 10vw;
        }
        .emailid {
          min-width: 20vw;
        }
        .mailsend {
          min-width: 5vw;
          img {
            width: 1.5vw;
          }
        }
        .viewstatus {
          position: relative;

          min-width: 5vw;

          img {
            width: 1.5vw;
          }
          .container {
            position: absolute;
            top: 25%;
            min-width: 1.5vw;
            left: 50%; 
            z-index: 1;
            .data {
              width: 12vw;
            }
          }
          .data {
            display: none;
            background-color: #D9D9D9;
            margin-left: 2vw;
            margin-top: -1vw;
            padding: 3px;
            border-radius: 1vw;
            div {
              margin-left: 1vw;
            }
            .label {
              font-size: 0.8vw;
              font-weight: bold;
              // margin-bottom: 0.5vw;
            }
            .clicked-label{
              color: white;
            }
            .values {
              padding-bottom: 2px;
            }
          }

          .container:hover .data {
            display: block;
            background-color: #858585;
            .viewstatus.opened .data {
              // display: block;
              display: block;
            }
          }
        }
      }
    }
    .contents {
      padding-bottom: 2vw;
      .label {
        font-weight: bold;
      }
    }
  }
  loader-container {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   
  
}
