
.login-model{

  
    min-width: 35vw;
    background-color: #090909;
    height: 30vw;
   .ant-modal-content {
      padding: 15% 12%;
      background-color: #090909;
      // border-radius: 30px 30px 30px 30px;
      // border: groove 10px;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #090909;
        color: #ff1f69;
        font-size: 1.5vw;
        margin-bottom: 2vw;
        .title-img {
        }

        .title-heading {
          margin-top: 5%;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-transform: uppercase;
          color: #000000;
        }
      }

      .ant-form-item-required {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-left: 10%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        color: white;
        width: 12vw;
      }

      .links {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #4f57aa;
      }

      .modal-form {
        .modal-login-input {
          border-radius: 10vw;
          margin-top: 1%;
          height: 45px;
          padding-left: 5%;
          border: 1.6px solid #dedede;
        }
        .modal-login-btn {
          width: 100%;
          border-radius: 10vw;
          border: none;
          background-color: #3b28ed;
          color: white;
          font-weight: 600;
          height: 45px;
          margin-top: 5%;
        }
      }
      .ant-modal-close-x {
        color: white;
      }
    //}
  }
}

// .lgn-btn::before{
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 0;
//   height: 0;
//   border-top: 2px solid  #ff1f69;
//   border-right: 2px solid  #ff1f69;
// animation: border-top 3s infinite alternate;
// }
// .lgn-btn::after{
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 0;
//   height: 0;
//   border-top: 2px solid  #ff1f69;
//   border-right: 2px solid  #ff1f69;
// animation: border-top 3s infinite alternate;
// }
// @keyframes border-top-right{
//   0% {width: 0px; height: 0px;}
//   25% {width: 200px; height: 0px;}
//   50% {width: 200px; height: 48px;}
//   100% {width: 200px; height: 48px;}

// }
// @keyframes border-top-left{
//   0% {width: 0px; height: 0px;}
//   25% {width: 0px; height: 0px;}
//   50% {width: 200px; height: 0px;}
//   100% {width: 200px; height: 48px;}

// }